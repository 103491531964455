import { httpsCallable } from 'firebase/functions';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { functions } from '@/firebase/firebase';
import useInput from '@/hooks/inputHook';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import { IComment } from '@/types/Collections/comments';
import { IAuthor } from '@/types/Collections/users';

type PostCommentReport = {
  reason: string;
  content: string;
  objectType: string;
  objectId: string;
  objectParentId: string;
  objectTitle: string;
  objectAuthor: IAuthor;
};

const PostCommentOptionsReport = ({
  comment,
  onClose,
  setDialogType,
}: {
  comment: IComment;
  onClose: () => void;
  setDialogType: (type: string) => void;
}): JSX.Element => {
  const { uid } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState(false);
  const {
    value: reason,
    bind: bindReason,
    reset: resetReason,
  } = useInput('spam');
  const {
    value: content,
    bind: bindContent,
    reset: resetContent,
  } = useInput('');

  const reportPost = async (reportData: Partial<PostCommentReport>) => {
    const report = httpsCallable(functions, 'moderationCallable-report');

    const reportObj = { ...reportData };
    reportObj.objectType = 'postComment';
    reportObj.objectId = comment.id;
    reportObj.objectParentId = comment.postId;
    reportObj.objectTitle = comment.content;
    reportObj.objectAuthor = comment.author;

    return report(reportObj)
      .then(() => {
        gtag.event({
          action: 'post.comment.report',
          params: {
            commentId: comment.id,
            postId: comment.postId,
            reporterUid: uid,
            reason: reportObj.reason,
          },
        });
        enqueueSnackbar(
          'Thank you. Your report has been sent to our moderators for review.',
          { variant: 'success' },
        );
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' });
        return false;
      });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const report = {
      reason,
      content,
    };
    if (reason === 'other' && report.content === '') {
      setError(true);
    } else {
      setError(false);
      reportPost(report);
      resetReason();
      resetContent();
      handleClose();
    }
  };

  return (
    <div className="card">
      <div className="card-content">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label
              htmlFor="post-comment-options-report-reason"
              className="label">
              Please let us know your reasons for reporting this comment
            </label>
            <div className="control">
              <div className="select">
                <select id="post-comment-report-reason" {...bindReason}>
                  <option value="spam">This comment is spam</option>
                  <option value="offensive">This comment is offensive</option>
                  <option value="abusive">This comment is abusive</option>
                  <option value="personal">
                    This comment contains personal contact information
                  </option>
                  <option value="worried">
                    I am worried about this person/member
                  </option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>
          <div className="field">
            <label htmlFor="post-comment-report-content" className="label">
              Please provide any further relevant information
            </label>
            <div className="control">
              <textarea
                id="post-comment-report-content"
                className="textarea"
                placeholder=""
                rows={3}
                {...bindContent}
              />
            </div>
            {error ? (
              <p className="help is-danger">Please enter more information.</p>
            ) : (
              ''
            )}
          </div>
          <div className="buttons modal-footer-buttons">
            <button
              type="button"
              aria-label="Close"
              onClick={() => setDialogType('default')}
              className="button is-light is-medium">
              Back
            </button>
            <button
              type="submit"
              aria-label="OK"
              className="button is-primary is-medium">
              Submit report
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostCommentOptionsReport;
