import { mdiDotsHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import CommentOptionsMenu from './CommentsOptionsMenu';
import { IComment } from '@/types/Collections/comments';

const PostCommentOptions = ({
  comment,
  setMentionUser,
}: {
  comment: IComment;
  setMentionUser: (username: string) => void;
}): JSX.Element => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        aria-label="Options"
        onClick={() => setDialogOpen(true)}
        className="button is-text is-small">
        <span className="icon">
          <Icon path={mdiDotsHorizontal} size={1} />
        </span>
      </button>
      <CommentOptionsMenu
        setMentionUser={setMentionUser}
        comment={comment}
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default PostCommentOptions;
