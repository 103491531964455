import { mdiImage } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import fuego from '@/firebase/fuego';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import Avatar from './Avatar';
import { db } from '@/firebase/firebase';

const storage = getStorage();

const ProfileProfileImgUpload = ({ imageName }) => {
  const { uid, user } = useAuth();
  const [isUploading, setUploading] = useState(false);

  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const update = async (payload) => {
    try {
      if (uid) {
        await updateDoc(doc(db, 'users', uid), payload);
      }
    } catch (err) {
      // console.log(err, 'error!');
    }
  };

  const handleUploadStart = async () => {
    if (!(!imageName || imageName === '')) {
      const name = imageName;
      try {
        const profileRef = ref(storage, `/users/${uid}/${name}`);
        await deleteObject(profileRef);

        const [thumbnailName] = name.split('.');
        const profileThumbRef = ref(storage, `/users/${uid}/thumb_${thumbnailName}.jpg`);
        await deleteObject(profileThumbRef);
      } catch (e) {
        // console.log('[Image delete]', e);
      }
    }
    setProgress(0);
    setUploading(true);
  };

  const handleUploadError = (err) => {
    setError(err);
    setUploading(false);
  };

  const handleUploadSuccess = async (filename) => {
    const name = await filename;

    update({
      profileImgUrl: '',
      profileImgUrlLastUpload: '',
      profileImgName: '',
    });
    fuego.storage
      .ref(`/users/${uid}`)
      .child(name)
      .getDownloadURL()
      .then((url) => {
        user.profileImgUrl = url;
        setUploading(false);
        update({
          profileImgUrl: url,
          profileImgUrlLastUpload: serverTimestamp(),
          profileImgName: name,
        });
        setProgress(100);
        gtag.event({
          action: 'profile.avatar.uploaded',
          params: {
            uid,
          },
        });
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleProgress = (prog) => {
    setProgress(prog);
  };

  if (!uid) {
    return null;
  }

  return (
    <label
      htmlFor="profile-image-upload-field"
      className={`profile-avatar-edit${error ? ' has-error' : ''}`}
    >
      <Avatar
        uid={uid}
        imageUrl={user?.profileImgUrl ? user.profileImgUrl : undefined}
        size={128}
        isUploading={isUploading}
      />
      <FileUploader
        hidden
        accept="image/*"
        storageRef={fuego.storage.ref(`users/${uid}`)}
        filename={(file) => `${uid}${Math.floor(Math.random() * 100)}.${file.type.split('/')[1]}`}
        onUploadStart={handleUploadStart}
        onUploadError={handleUploadError}
        onUploadSuccess={handleUploadSuccess}
        onProgress={handleProgress}
        maxWidth={192}
        maxHeight={192}
        id="profile-image-upload-field"
      />
      <span className="profile-avatar-edit-progress">{`${progress}%`}</span>
      <span className="profile-avatar-edit-hover">
        <Icon path={mdiImage} size={2} />
      </span>
    </label>
  );
};

ProfileProfileImgUpload.propTypes = {
  imageName: PropTypes.string.isRequired,
};

export default ProfileProfileImgUpload;
