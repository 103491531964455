import {
  mdiCheck, mdiClose, mdiDelete, mdiRestore,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useAuth } from '../../providers/AuthProvider';
import { db, functions } from '../../firebase/firebase';

const Dialog = dynamic(() => import('@mui/material/Dialog'), {
  ssr: false,
});

const PostCommentModerate = (props) => {
  const { comment } = props;
  const { isAdmin, uid, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('default');

  const update = async (payload) => {
    try {
      await updateDoc(doc(db, 'comments', comment.id), payload);
    } catch (error) {
      console.log(error, 'error!');
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setModalType('default');
  };

  const deleteConfirm = async () => {
    if (isAdmin) {
      setModalType('waiting');
      const unpublishedDate = serverTimestamp();
      await update({
        isPublished: false,
        unpublishedDate,
        unpublishedBy: {
          uid,
          username: user.username,
        },
      });

      const moderate = httpsCallable(
        functions,
        'moderationCallable-moderate',
      );

      const moderateObj = {
        objectType: 'postComment',
        objectId: comment.id,
        action: 'unpublish',
      };

      moderate(moderateObj).catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
      enqueueSnackbar('Comment unpublished successfully.', {
        variant: 'success',
      });
    }
    handleClose();
  };

  const restoreConfirm = async () => {
    if (isAdmin) {
      setModalType('waiting');
      const restoredDate = serverTimestamp();
      await update({
        isPublished: true,
        restoredDate,
        restoredBy: {
          uid,
          username: user.username,
        },
      });
      enqueueSnackbar('Comment status changed successfully', {
        variant: 'success',
      });

      const moderate = httpsCallable(
        functions,
        'moderationCallable-moderate',
      );

      const moderateObj = {
        objectType: 'postComment',
        objectId: comment.id,
        action: 'publish',
      };

      moderate(moderateObj).catch((err) => {
        enqueueSnackbar.error(err.message, { variant: 'error' });
      });
    }
    handleClose();
  };

  const modalContent = () => {
    switch (modalType) {
      case 'delete':
        return (
          <div className="card confirm-dialog">
            <div className="card-content">
              <p className="mb-4 is-font-brand is-size-4">
                Are you sure you wish to unpublish this comment?
              </p>
              <div className="buttons modal-footer-buttons">
                <button
                  type="button"
                  aria-label="Close"
                  onClick={handleClose}
                  className="button is-light is-medium"
                >
                  <span className="icon">
                    <Icon path={mdiClose} size={1.25} />
                  </span>
                </button>
                <button
                  type="button"
                  aria-label="OK"
                  onClick={() => deleteConfirm()}
                  className="button is-danger is-medium"
                >
                  <span className="icon">
                    <Icon path={mdiCheck} size={1.25} />
                  </span>
                </button>
              </div>
            </div>
          </div>
        );

      case 'restore':
        return (
          <div className="card confirm-dialog">
            <div className="card-content">
              <p className="mb-4 is-font-brand is-size-4">
                Are you sure you wish to restore this comment?
              </p>
              <div className="buttons modal-footer-buttons">
                <button
                  type="button"
                  aria-label="Close"
                  onClick={handleClose}
                  className="button is-light is-medium"
                >
                  <span className="icon">
                    <Icon path={mdiClose} size={1.25} />
                  </span>
                </button>
                <button
                  type="button"
                  aria-label="OK"
                  onClick={() => restoreConfirm()}
                  className="button is-danger is-medium"
                >
                  <span className="icon">
                    <Icon path={mdiCheck} size={1.25} />
                  </span>
                </button>
              </div>
            </div>
          </div>
        );

      case 'waiting':
        return (
          <div className="card confirm-dialog">
            <div className="card-content">
              <div className="loading-block" />
            </div>
          </div>
        );

      case 'default':
      default:
        return (
          <div className="card confirm-dialog">
            <div className="card-content" />
          </div>
        );
    }
  };

  return (
    <div className="comment-moderate">
      {!comment.isPublished && comment.unpublishedBy ? (
        <span className="comment-moderate-meta">
          <span className="tag is-danger">
            Unpublished by @
            {comment.unpublishedBy.username}
          </span>
        </span>
      ) : (
        ''
      )}
      <button
        onClick={() => {
          setDialogOpen(true);
          if (comment.isPublished) {
            setModalType('delete');
          } else {
            setModalType('restore');
          }
        }}
        className="button is-small is-white"
        type="button"
      >
        <span className="icon">
          <Icon path={comment.isPublished ? mdiDelete : mdiRestore} size={1} />
        </span>
      </button>
      <Dialog open={isDialogOpen} onClose={handleClose}>
        {modalContent()}
      </Dialog>
    </div>
  );
};

PostCommentModerate.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isPublished: PropTypes.bool,
    unpublishedBy: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
};

export default PostCommentModerate;
