import { useAuth } from '@/providers/AuthProvider';
import { IProfile } from '@/types/Collections/users';

const ProfileStreamNoPosts = ({
  profile,
}: {
  profile: IProfile;
}): JSX.Element => {
  const { uid } = useAuth();
  return (
    <div className="card has-background-dark mt-4">
      <div className="card-content has-text-white">
        {uid === profile.uid ? (
          <>
            <h2 className="title is-size-2 has-text-white has-text-centered">
              When you write some posts they will appear here.
            </h2>
            <p className="has-text-centered">
              Click the &ldquo;Write a post&rdquo; button to ask the community
              your questions, or just vent.
            </p>
          </>
        ) : (
          <h2 className="title is-size-2 has-text-white has-text-centered">
            Sorry, there are no posts yet.
          </h2>
        )}
      </div>
    </div>
  );
};

export default ProfileStreamNoPosts;
