import { IProfile } from '@/types/Collections/users';
import useFollowers from './useFollowers';
import ProfileListItem from './item';

const ProfileFollowers = ({
  profile,
  type = 'following',
}: {
  profile: IProfile;
  type: 'following' | 'followedBy';
}): JSX.Element => {
  const { followers, isLoading, addPage, endOfFollowers } = useFollowers(
    type,
    profile.uid,
  );

  return (
    <div className="profile-following-section container is-narrow">
      <h2 className="title is-size-3 has-text-centered">
        {type === 'following' ? `Following` : `Followers`}
      </h2>
      <ul className="profile-follow-list">
        {followers?.map(page =>
          page.profiles.map(pUid => (
            <ProfileListItem profileUid={pUid} key={pUid} />
          )),
        )}
      </ul>
      {isLoading && <div className="loading-block" />}
      {!endOfFollowers && (
        <button
          type="button"
          onClick={() => addPage()}
          className={`button is-fullwidth is-large`}>
          Load more
        </button>
      )}
    </div>
  );
};

export default ProfileFollowers;
