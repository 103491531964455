import dayjs from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import { Timestamp } from '@/types/Firebase/firebase';

dayjs.extend(Calendar);

const DateTimeFormatted = ({
  timestamp,
  format = 'D MMM YYYY HH:mm',
}: {
  timestamp: Date | Timestamp;
  format?: string;
}): JSX.Element => {
  let jsDate = timestamp;
  if (!timestamp) {
    return <time>Now</time>;
  }

  if (timestamp._seconds) {
    jsDate = timestamp._seconds * 1000;
  } else if (timestamp.seconds) {
    jsDate = timestamp.seconds * 1000;
  }

  const day = dayjs(jsDate);

  let formattedDate = day.calendar(null, {
    lastDay: '[Yesterday at] HH:mm',
    sameDay: '[Today at] HH:mm',
    nextDay: '[Tomorrow at] HH:mm',
    lastWeek: '[last] dddd [at] HH:mm',
    nextWeek: 'dddd [at] HH:mm',
    sameElse: format,
  });
  if (format) {
    formattedDate = day.format(format);
  }
  return <time dateTime={formattedDate}>{formattedDate}</time>;
};

export default DateTimeFormatted;
