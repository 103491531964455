import useSWRInfinite from 'swr/infinite';
import { useCallback, useState, useEffect } from 'react';
import {
  getDocs,
  getDoc,
  doc,
  query,
  collection,
  where,
  limit,
  orderBy,
  startAfter,
} from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { IActivityPost, IActivityPostMissing } from '@/types/Collections/posts';
import { Timestamp } from '@/types/Firebase/firebase';

const ITEMS_PER_PAGE = 20;

interface ActivityItem {
  id: string;
  created: Timestamp;
  type?: string;
}
interface PostStreamItem {
  id: string;
  activity: ActivityItem;
  post: IActivityPost | IActivityPostMissing;
}

type UsePostStream = {
  key: string | null;
  posts: PostStreamItem[][] | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
  addPage: () => void;
  endOfPosts: boolean;
};

const usePostStream = (key: string | null): UsePostStream => {
  const [lastItem, setLastItem] = useState<ActivityItem | null>(null);

  const fetcher = useCallback(
    async (pageKey: string): Promise<PostStreamItem[]> => {
      if (key === null) return [];
      const isFirstPage = pageKey.substring(pageKey.length - 1) === '0';

      const ref = collection(db, key);
      const constraints = [
        where('isPublished', '==', true),
        where('isDeleted', '==', false),
        orderBy('created', 'desc'),
        limit(ITEMS_PER_PAGE),
      ] as any[];

      if (lastItem && !isFirstPage) {
        constraints.push(startAfter(lastItem.created));
      }

      return getDocs(query(ref, ...constraints)).then(async querySnapshot => {
        const items = [] as ActivityItem[];
        querySnapshot.forEach(async snapshotDoc => {
          const item = snapshotDoc.data();
          item.id = snapshotDoc.id;
          items.push(item as ActivityItem);
        });

        const promises = items.map(async item => {
          return getDoc(doc(db, 'posts', item.id)).then(p => {
            if (p.exists()) {
              const post = { ...p.data(), id: p.id } as IActivityPost;
              if (post.isPublished && !post.isDeleted) {
                return { activity: item, post, id: item.id };
              }
            }
            return {
              id: item.id,
              activity: item,
              post: {
                id: item.id,
                error: 'Missing post',
              },
            } as PostStreamItem;
          });
        });

        return Promise.allSettled(promises).then(results => {
          return results.map((result, i) => {
            if (result.status === 'fulfilled') {
              return result.value as PostStreamItem;
            }
            return {
              id: items[i].id,
              activity: items[i],
              post: {
                id: items[i].id,
                error: 'Failed to fetch post',
              },
            };
          });
        });
      });
    },
    [key, lastItem],
  );

  const swrKey = (pageIndex: number) => {
    if (key === null) return null;
    return `${key}?page=${pageIndex}`;
  };

  const { data, error, isLoading, isValidating, setSize } = useSWRInfinite(
    swrKey,
    fetcher,
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateFirstPage: false,
      refreshInterval: 0,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    },
  );

  useEffect(() => {
    if (typeof data !== 'undefined' && data.length && data[0].length) {
      const lastActivityItem =
        data[data.length - 1][data[data.length - 1].length - 1];
      setLastItem(lastActivityItem.activity);
    }
  }, [data]);

  const addPage = () => {
    setSize(size => size + 1);
  };

  return {
    posts: data,
    key,
    isLoading,
    isValidating,
    error,
    addPage,
    endOfPosts:
      data && data[data.length - 1].length < ITEMS_PER_PAGE ? true : false,
  };
};

export default usePostStream;
