import CollectionCard from '@/components/Stream/Collection/CollectionCard';
import { IProfile } from '@/types/Collections/users';
import useUserCollections from '@/hooks/useUserCollections';

const ProfileCollections = ({
  profile,
}: {
  profile: IProfile;
}): JSX.Element => {
  const { collections: userCollections, isLoading } = useUserCollections(
    profile.uid,
  );

  if (isLoading) {
    return <div className="loading-block" />;
  }

  return (
    <div className="container is-narrow">
      <div className="collections-cards">
        {userCollections && userCollections.length ? (
          <ul>
            {userCollections.map(
              userCollection =>
                !userCollection.isDeleted && (
                  <li key={userCollection.id}>
                    <CollectionCard collection={userCollection} />
                  </li>
                ),
            )}
          </ul>
        ) : (
          <div className="card has-background-dark">
            <div className="card-content has-text-white">
              <h2 className="title is-size-2 has-text-white has-text-centered">
                Save posts to a collection for later.
              </h2>
              <p className="has-text-centered">
                These could be posts you bookmark so that you can find them
                again easily, or lists of posts to share with other members.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileCollections;
