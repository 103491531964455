import dayjs from 'dayjs';
import { Timestamp } from '@/types/Firebase/firebase';

const Age = ({ timestamp }: { timestamp: Timestamp }): JSX.Element | null => {
  if (typeof timestamp.toDate === 'function') {
    return <>{dayjs().diff(dayjs(timestamp.toDate()), 'years')}</>;
  }
  if (timestamp) {
    return <>{dayjs().diff(dayjs(timestamp), 'years')}</>;
  }
  return null;
};

export default Age;
