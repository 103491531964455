const MentionStyles = {
  '&multiLine': {
    control: {
      position: 'relative',
    },
    highlighter: {
      position: 'absolute',
    },

    input: {
      resize: 'vertical',
      overflow: 'auto',
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '48px',
      maxHeight: '128px',
      height: '48px',
      marginLeft: '0px',
    },
  },

  suggestions: {
    list: {
      position: 'absolute',
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      marginBottom: '14px',
      marginTop: '0px',
      bottom: '0px',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#7EC4A3',
      },
    },
  },
};
export default MentionStyles;
