import Head from 'next/head';
import React from 'react';
import { useAuth } from '@/providers/AuthProvider';
import useProfile from '@/hooks/useProfile';
import Profile404 from './Profile404';
import ProfileAbout from './ProfileAbout';
import ProfileCheck from './ProfileCheck';
import ProfileCollections from './ProfileCollections';
import ProfileScheduled from './ProfileScheduled';
import ProfileFollowers from './Followers';
import ProfileHeader from './ProfileHeader';
import ProfileLoggedOut from './ProfileLoggedOut';
import ProfileStream from './ProfileStream';
import ProfileTabs from './ProfileTabs';

const Profile = ({
  page,
  username,
}: {
  page:
    | 'posts'
    | 'collections'
    | 'about'
    | 'followers'
    | 'following'
    | 'scheduled';
  username: string;
}): JSX.Element | null => {
  const { authLoaded, isLoggedIn } = useAuth();
  const { profile, isLoading, error } = useProfile(username);

  if (!isLoggedIn) {
    return <ProfileLoggedOut username={username} />;
  }

  if (!authLoaded) {
    return <div className="loading-block" />;
  }

  if (!isLoading && error) {
    return <Profile404 username={username} error={error} />;
  }

  if (!profile) {
    return null;
  }

  const renderPage = () => {
    if (profile) {
      switch (page) {
        case 'about':
          return <ProfileAbout profile={profile} />;

        case 'collections':
          return <ProfileCollections profile={profile} />;

        case 'followers':
          return <ProfileFollowers type={'followedBy'} profile={profile} />;

        case 'following':
          return <ProfileFollowers type={'following'} profile={profile} />;

        case 'scheduled':
          return <ProfileScheduled profile={profile} />;

        default:
          return <ProfileStream profile={profile} />;
      }
    }
    return null;
  };

  return (
    <div className="profile container page-container">
      <Head>
        <title>{`@${profile.username} on Shift.ms`}</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/@${profile.username}`}
        />
        <meta property="og:type" content="profile" />
        <meta name="robots" content="noindex" key="robots" />
      </Head>
      <ProfileCheck profile={profile}>
        <ProfileHeader profile={profile} />
        <ProfileTabs page={page} profile={profile} />
        {renderPage()}
      </ProfileCheck>
    </div>
  );
};

export default Profile;
