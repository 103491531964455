import useBlock from '@/hooks/useBlock';
import AuthErrorMessage from '@/components/Auth/AuthErrorMessage';
import { IProfile } from '@/types/Collections/users';
import ProfileBlocked from './ProfileBlocked';

const ProfileCheck = ({
  children,
  profile,
}: {
  children: React.ReactNode;
  profile: IProfile;
}): JSX.Element | null => {
  const { status, error } = useBlock(profile.uid);

  if (status === 'ok') {
    return <>{children}</>;
  }

  if (status === 'loading') {
    return null;
  }

  if (status === 'error') {
    return <AuthErrorMessage error={error} />;
  }

  if (status === 'blocking' || status === 'blocked') {
    return <ProfileBlocked profile={profile} status={status} />;
  }

  return null;
};

export default ProfileCheck;
