import { useState } from 'react';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import SupportIcon from '@/components/svg/SupportIcon';
import useReaction from '@/hooks/useReaction';
import { IComment } from '@/types/Collections/comments';
import { useSignupBanner } from '@/providers/SignupBannerProvider';

const PostCommentReaction = ({
  comment,
}: {
  comment: IComment;
}): JSX.Element => {
  const { uid, isVerified, showVerifyEmailDialog } = useAuth();
  const { open: openSignupBanner } = useSignupBanner();
  const { reaction, addReaction, deleteReaction } = useReaction(
    'comment',
    comment.id,
    uid,
  );

  let commentReactionsCount = 0;

  if (comment.reactions) {
    const commentReactions = Object.values(comment.reactions);
    commentReactionsCount = commentReactions.reduce((a, b) => a + b, 0);
  }

  const [reactionsCount, setReactionsCount] = useState(commentReactionsCount);

  const handleSupportClick = () => {
    if (uid && isVerified) {
      if (reaction) {
        deleteReaction();
        gtag.event({
          action: 'comment.reaction.remove',
          params: {
            commentId: comment.id,
            postId: comment.postId,
            reactionType: 'support',
            uid,
          },
        });
        setReactionsCount(reactionsCount - 1);
      } else {
        addReaction();
        gtag.event({
          action: 'comment.reaction.add',
          params: {
            commentId: comment.id,
            postId: comment.postId,
            reactionType: 'support',
            uid,
          },
        });
        setReactionsCount(reactionsCount + 1);
      }
    } else if (uid) {
      showVerifyEmailDialog();
    } else {
      openSignupBanner();
    }
    return null;
  };

  return (
    <div className="control">
      <button
        type="button"
        onClick={handleSupportClick}
        aria-label="Support"
        className="comment-support button button-has-count is-text">
        <span className="icon">
          <SupportIcon
            fill={reaction ? '#EB5E62' : '#3D4859'}
            width={24}
            height={24}
          />
        </span>
      </button>
      {reactionsCount > 0 ? (
        <span className="button-count tag reactionCount">{reactionsCount}</span>
      ) : (
        ''
      )}
    </div>
  );
};

export default PostCommentReaction;
