import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { doc, updateDoc } from 'firebase/firestore';
import { useSnackbar } from 'notistack';
import { db } from '@/firebase/firebase';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import { IComment } from '@/types/Collections/comments';

const PostCommentOptionsDelete = ({
  comment,
  onClose,
}: {
  comment: IComment;
  onClose: () => void;
}): JSX.Element => {
  const { uid } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const update = async (payload: Partial<IComment>) => {
    try {
      if (comment) {
        await updateDoc(doc(db, 'comments', comment.id), payload);
      }
    } catch (error) {
      // console.log(error, 'error!');
    }
  };

  const handleClose = () => {
    onClose();
  };

  const commentDeleteConfirm = async () => {
    if (uid === comment.author.uid) {
      const deletedDate = new Date();
      await update({
        isDeleted: true,
        content: 'Comment deleted.',
        deletedContent: comment.content,
        deletedDate,
      });
      enqueueSnackbar('Your comment has been deleted', { variant: 'success' });
    } else {
      enqueueSnackbar("You can't delete someone else's comment", {
        variant: 'error',
      });
    }
    gtag.event({
      action: 'post.comment.delete',
      params: {
        commentId: comment.id,
        postId: comment.postId,
        uid,
      },
    });
    handleClose();
  };

  return (
    <div className="card confirm-dialog">
      <div className="card-content">
        <p className="mb-4 is-font-brand is-size-4">
          Are you sure you wish to delete this comment?
        </p>
        <div className="buttons modal-footer-buttons">
          <button
            type="button"
            aria-label="Close"
            onClick={() => handleClose()}
            className="button is-light is-medium">
            <span className="icon">
              <Icon path={mdiClose} size={1.25} />
            </span>
          </button>
          <button
            type="button"
            aria-label="OK"
            onClick={() => commentDeleteConfirm()}
            className="button is-danger is-medium">
            <span className="icon">
              <Icon path={mdiCheck} size={1.25} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostCommentOptionsDelete;
