import PropTypes from 'prop-types';
import React from 'react';

const PostBlocked = ({ post, status }) => {
  if (status === 'blocked') {
    return (
      <div className="container is-narrow">
        <div className="box content">
          <h1 className="title">
            @
            {post.author.username}
            {' '}
            has blocked you from viewing their profile
          </h1>
          <p>
            You won&apos;t be able to view their personal information or comment
            on their posts.
          </p>
        </div>
      </div>
    );
  }
  if (status === 'blocking') {
    return (
      <div className="container is-narrow">
        <div className="box content">
          <h1 className="title">
            You are blocking @
            {post.author.username}
          </h1>
          <p>
            You won&apos;t be able to view their personal information or comment
            on their posts.
          </p>
        </div>
      </div>
    );
  }
  return null;
};

PostBlocked.propTypes = {
  post: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
};

export default PostBlocked;
