import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { collectionUrl, truncateString } from '@/lib/utils';
import DateTimeFormatted from '@/components/General/DateTimeFormatted';
import PrivacyLock from './PrivacyLock';
import { ICollection } from '@/types/Collections/collections';

const CollectionCard = ({
  collection,
}: {
  collection: ICollection;
}): JSX.Element => (
  <Link legacyBehavior href="/[...slug]" as={collectionUrl(collection)}>
    <a className="is-block">
      <div
        className={`card collection-card${
          collection.colourScheme ? ` colour-${collection.colourScheme}` : ''
        }`}>
        <div className="collection-card-header is-flex is-justify-content-space-between is-align-items-center">
          <div>
            {collection.thumbnail ? (
              <div className="collection-card-header-image">
                <figure className="image is-64x64">
                  <Image
                    src={collection.thumbnail}
                    alt=""
                    width={64}
                    height={64}
                  />
                </figure>
              </div>
            ) : (
              ''
            )}
            <div className="collection-card-header-content ">
              <h3 className="title is-size-4 mb-0">{collection.title}</h3>
            </div>
          </div>
          {collection.isPrivate && <PrivacyLock userCollection={collection} />}
        </div>
        <div className="collection-card-content">
          {collection.description ? (
            <p className="is-font-brand">
              {truncateString(collection.description, 100)}
            </p>
          ) : (
            ''
          )}
          {collection.lastActive && (
            <p className="is-size-7">
              Last updated:{' '}
              <DateTimeFormatted timestamp={collection.lastActive} />
            </p>
          )}
        </div>
      </div>
    </a>
  </Link>
);

export default CollectionCard;
