import useBlock from '@/hooks/useBlock';
import ErrorMessage from '@/components/General/ErrorMessage';
import PostBlocked from './PostBlocked';
import { IPost } from '@/types/Collections/posts';

const PostCheck = ({
  children,
  post,
}: {
  children: React.ReactNode;
  post: IPost;
}): JSX.Element | null => {
  const { status, error } = useBlock(post.author.uid);

  if (status === 'ok' || status === 'not-logged-in') {
    return <>{children}</>;
  }

  if (status === 'loading') {
    return null;
  }

  if (status === 'error') {
    return <ErrorMessage error={error} />;
  }

  if (status === 'blocking' || status === 'blocked') {
    return <PostBlocked post={post} status={status} />;
  }

  return null;
};

export default PostCheck;
