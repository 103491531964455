import { useRouter } from 'next/router';
import ReactModal from 'react-modal';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import Profile from '@/components/Profile/Profile';
import Post from '@/components/Stream/Post';
import ErrorPage from './ErrorPage';

const PageModal = ({
  parent = '/home',
}: {
  parent: string | undefined;
}): JSX.Element | null => {
  const router = useRouter();
  const { resetMessage } = useSignupBanner();

  if (!parent || !router.query.username) {
    return null;
  }

  const renderPage = () => {
    if (router.query.postId) {
      return (
        <Post
          postId={
            Array.isArray(router.query.postId)
              ? router.query.postId[0]
              : router.query.postId
          }
        />
      );
    }
    if (router.query.username) {
      return (
        <Profile
          username={
            Array.isArray(router.query.username)
              ? router.query.username[0]
              : router.query.username
          }
          page="posts"
        />
      );
    }
    return <ErrorPage error={new Error('Page not found.')} />; // This shouldn't happen
  };

  return (
    <ReactModal
      isOpen={!!router.query.username}
      onAfterOpen={() => {
        document.documentElement.classList.add('is-clipped');
      }}
      onAfterClose={() => {
        document.documentElement.classList.remove('is-clipped');
        resetMessage();
      }}
      onRequestClose={() => router.push(parent, parent, { shallow: true })}>
      {renderPage()}
    </ReactModal>
  );
};

export default PageModal;
