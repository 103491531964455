import Head from 'next/head';
import Link from 'next/link';
import { useAuth } from '@/providers/AuthProvider';
import Avatar from './Avatar';

const ProfileLoggedOut = ({ username }: { username: string }): JSX.Element => {
  const { authLoaded } = useAuth();

  return (
    <div className="profile container page-container">
      <Head>
        <title>{`@${username} on Shift.ms`}</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/@${username}`}
        />
        <meta property="og:type" content="profile" />
      </Head>
      <div className="container is-narrow">
        <header className="profile-header">
          <figure className="profile-image">
            <div className="image is-128x128">
              <Avatar imageUrl={null} username={username} size={128} />
            </div>
          </figure>
          <div className="profile-name">
            <h1 className="profile-display-name title is-3">@{username}</h1>
          </div>
        </header>
        {authLoaded && (
          <div className="card has-background-dark mt-4">
            <div className="card-content has-text-white">
              <h2 className="title is-size-2 has-text-white">
                Connect with @{username} and other MSers on Shift.ms
              </h2>
              <div className="buttons">
                <Link
                  href="/join"
                  role="button"
                  tabIndex={0}
                  className="button is-primary is-medium">
                  Join the community
                </Link>
                <Link href="/signin" className="button is-medium" tabIndex={0}>
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileLoggedOut;
