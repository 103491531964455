import React, { useCallback, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '@/firebase/firebase';
import usePostStream from '@/hooks/usePostStream';
import PostCard from '@/components/Stream/PostCard';
import ProfileStreamNoPosts from './ProfileStreamNoPosts';
import { IProfile } from '@/types/Collections/users';
import { IActivityPost } from '@/types/Collections/posts';

const ProfileStream = ({ profile }: { profile: IProfile }): JSX.Element => {
  const { posts, addPage, endOfPosts, isLoading } = usePostStream(
    profile.uid ? `/users/${profile.uid}/activity` : null,
  );

  const updatePostRefsInUsers = useCallback(async (uid: string) => {
    const updatePostRefsInUsersCallable = httpsCallable(
      functions,
      'usersCallable-updatePostRefsInUsers',
    );

    await updatePostRefsInUsersCallable({
      id: uid,
    });
  }, []);

  // TODO
  useEffect(() => {
    if (profile && !profile.isUserActivitiesPopulated) {
      updatePostRefsInUsers(profile.uid);
    }
  }, [profile, profile.uid, updatePostRefsInUsers]);

  if (isLoading) {
    return <div className="loading-block" />;
  }

  if (!posts || !posts[0][0]) {
    return (
      <div className="container is-narrow">
        <ProfileStreamNoPosts profile={profile} />
      </div>
    );
  }

  const postsList = () => {
    return posts.map(page =>
      page.map(item => {
        if (item.post && !item.post.error) {
          return (
            <PostCard
              key={item.id}
              post={item.post as IActivityPost}
              activityType={item.activity.type ? item.activity.type : 'post'}
            />
          );
        }
        return (
          <div key={item.id} className="mb-4">
            <div className="notification is-danger is-light">
              <p>Post not available.</p>
              <p>
                <small>
                  ({item.post.error}: {item.id})
                </small>
              </p>
            </div>
          </div>
        );
      }),
    );
  };

  return (
    <div className="container is-narrow">
      <div className="posts">{postsList()}</div>
      {!endOfPosts ? (
        <button
          type="button"
          onClick={() => addPage()}
          className={`button is-fullwidth is-large${
            isLoading ? ' is-loading' : ''
          }`}>
          Load more
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProfileStream;
