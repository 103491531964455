import { increment, serverTimestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import NextError from 'next/error';
import Head from 'next/head';
import { useCallback, useEffect } from 'react';
import { functions } from '@/firebase/firebase';
import { postUrl } from '@/lib/utils';
import { useAuth } from '@/providers/AuthProvider';
import { useSignupBanner } from '@/providers/SignupBannerProvider';
import ErrorMessage from '@/components/General/ErrorMessage';
import PageLoading from '@/components/General/PageLoading';
import { updateSettings } from '@/providers/AuthProvider/hooks/useSettings';
import usePost from '@/hooks/usePost';
import PostCard from './PostCard';
import PostCheck from './PostCheck';
import PostComments from './PostComments';
import PostProfileCard from './PostProfileCard';
import { IPost } from '@/types/Collections/posts';

const Post = (props: { postId: string; initialData?: IPost }): JSX.Element => {
  const { postId, initialData } = props;
  const { uid, isLoggedIn, authLoaded, auth, settings } = useAuth();
  const { setMessage } = useSignupBanner();

  const { post, error, isLoading, isValidating } = usePost(postId, initialData);

  const incrementPostViewCount = useCallback(() => {
    if (uid) {
      const lastPostViewTime = serverTimestamp();

      updateSettings(uid, {
        postViewCount: increment(1),
        lastPostViewTime,
      });
    }
  }, [uid]);

  useEffect(() => {
    if (
      uid &&
      (!settings?.postViewCount || Number(settings?.postViewCount) < 4)
    ) {
      incrementPostViewCount();
    }
  }, [settings?.postViewCount, uid, incrementPostViewCount]);

  useEffect(() => {
    const eventAdd = async () => {
      const eventAddFunction = httpsCallable(
        functions,
        'eventCallable-eventAdd',
      );
      eventAddFunction({
        uid,
        eventType: 'POST:VIEW',
        eventSource: 'POST',
        eventSourceId: postId,
        meta: {},
        email: auth?.email,
        platform: 'web',
      });
    };
    if (authLoaded && isLoggedIn && uid && postId && auth?.email) {
      eventAdd();
    }
  }, [authLoaded, isLoggedIn, uid, postId, auth?.email]);

  useEffect(() => {
    if (post && post.commentsCount) {
      setMessage(
        `View ${post.commentsCount} ${
          post.commentsCount > 1 ? 'replies' : 'reply'
        } by becoming a member`,
      );
    } else {
      setMessage('Join the conversation by becoming a member');
    }
  }, [post, setMessage]);

  if (error) {
    return (
      <div className="post container page-container">
        <ErrorMessage error={error} />
      </div>
    );
  }

  if (!post) {
    return <PageLoading />;
  }

  if (post && !post.id && !isLoading && !isValidating) {
    return <NextError statusCode={404} />;
  }

  return (
    <div id="post" className="post container page-container has-sidebar">
      <Head>
        <title>{`@${post.title} on Shift.ms`}</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${postUrl(post)}`}
        />
        <meta
          property="article:author"
          content={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/@${post.author.username}`}
        />
        <meta property="og:type" content="article" />
        {!post?.isIndexed ? (
          <meta name="robots" content="noindex" key="robots" />
        ) : (
          ''
        )}
      </Head>
      <PostCheck post={post}>
        <div className="container is-narrow">
          <PostCard key={post.id} post={post} expanded />
          <PostComments post={post} />
        </div>

        <PostProfileCard post={post} />
      </PostCheck>
    </div>
  );
};

export default Post;
