import { mdiLock, mdiLockOff } from '@mdi/js';
import Icon from '@mdi/react';
import { ICollection } from '../../../types/Collections/collections';

const PrivacyLock = ({
  userCollection,
}: {
  userCollection: ICollection;
}): JSX.Element => (
  <div
    className={`privacy-lock${
      userCollection.adminLockedPrivate === true ? ` admin-locked` : ``
    }`}>
    {userCollection.isPrivate ? (
      <>
        <Icon path={mdiLock} size={0.5} />
        <span>Private</span>
      </>
    ) : (
      <>
        <Icon path={mdiLockOff} size={0.5} />
        <span>Public</span>
      </>
    )}
  </div>
);

export default PrivacyLock;
