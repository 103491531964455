import Link from 'next/link';
import { IProfile } from '@/types/Collections/users';

const ProfileTabs = ({
  profile,
  page,
}: {
  profile: IProfile;
  page:
    | 'posts'
    | 'collections'
    | 'about'
    | 'followers'
    | 'following'
    | 'scheduled';
}): JSX.Element => {
  return (
    <div className="container is-narrow">
      <div className="tabs profile-tabs is-toggle is-fullwidth">
        <ul>
          <li className={page === 'posts' ? 'is-active' : ''}>
            <Link href={`/@${profile.username}`}>Activity</Link>
          </li>
          <li className={page === 'collections' ? 'is-active' : ''}>
            <Link href={`/@${profile.username}/collections`}>Collections</Link>
          </li>
          <li className={page === 'about' ? 'is-active' : ''}>
            <Link href={`/@${profile.username}/about`}>About</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileTabs;
