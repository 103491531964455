import { flag } from 'country-emoji';
import Link from 'next/link';
import { truncateString } from '@/lib/utils';
import { useAuth } from '@/providers/AuthProvider';
import ErrorMessage from '@/components/General/ErrorMessage';
import Age from '@/components/Profile/Age';
import ProfileFollowButton from '@/components/Profile/ProfileFollowButton';
import { IPost } from '@/types/Collections/posts';
import useUser from '@/hooks/useUser';

const PostProfileCard = ({ post }: { post: IPost }): JSX.Element | null => {
  const { isLoggedIn } = useAuth();

  const { user, error } = useUser(post.author.uid ? post.author.uid : null);

  if (!isLoggedIn) {
    return null;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!user) {
    return <div className="post-profile-card side-card" />;
  }

  return (
    <div className="post-profile-card side-card">
      <div className="container is-narrow">
        <div className="profile-card">
          <div className="card">
            <div className="card-content">
              <div className="">
                <h2>
                  <Link href={`/@${post.author.username}`}>
                    @{post.author.username}
                  </Link>
                </h2>
                {user ? (
                  <>
                    <div className="profile-card-intro">
                      {user.country ? (
                        <div className="profile-card-flag">
                          {flag(user.country)}
                        </div>
                      ) : (
                        ''
                      )}
                      {user.dob ? (
                        <div className="profile-card-age">
                          <Age timestamp={user.dob} /> years old
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <ul className="is-font-brand profile-card-list">
                      {user.msConnection &&
                      user.msConnection === 'I have MS' &&
                      user.msType ? (
                        <li className="">{user.msType}</li>
                      ) : (
                        <li className="">{user.msConnection || ''}</li>
                      )}
                      {user.treatment ? (
                        <li className="">{user.treatment}</li>
                      ) : (
                        ''
                      )}
                    </ul>
                    {user.bio ? (
                      <div className="bio is-font-brand">
                        {truncateString(user.bio, 200)}
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
                <div className="buttons profile-card-buttons">
                  <Link
                    legacyBehavior
                    href="/[...slug]"
                    as={`/@${post.author.username}`}>
                    <a className="button is-rounded is-fullwidth profile-card-link">
                      View
                    </a>
                  </Link>
                </div>
                <div>
                  <ProfileFollowButton
                    profile={post.author}
                    fullWidth="is-fullwidth"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostProfileCard;
