import Head from 'next/head';
import Avatar from './Avatar';

const Profile404 = ({
  username,
  error,
}: {
  username: string;
  error: Error;
}): JSX.Element => {
  return (
    <div className="profile container page-container">
      <Head>
        <title>{`@${username} on Shift.ms`}</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/@${username}`}
        />
        <meta property="og:type" content="profile" />
      </Head>
      <div className="container is-narrow">
        <header className="profile-header">
          <figure className="profile-image">
            <div className="image is-128x128">
              <Avatar imageUrl={null} username={username} size={128} />
            </div>
          </figure>
          <div className="profile-name">
            <h1 className="profile-display-name title is-3">@{username}</h1>
          </div>
        </header>
        <div className="card has-background-dark mt-4">
          <div className="card-content has-text-white">
            <h2 className="title is-size-2 has-text-white has-text-centered">
              {error.message}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile404;
