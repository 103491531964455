import useSWRInfinite from 'swr/infinite';
import { useCallback, useState, useEffect } from 'react';
import {
  getDocs,
  query,
  collection,
  limit,
  orderBy,
  startAfter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { db } from '@/firebase/firebase';

const ITEMS_PER_PAGE = 10;

type followersPage = {
  profiles: string[];
  lastDoc: QueryDocumentSnapshot | null;
};

type UseFollowers = {
  followers: followersPage[] | undefined; // uids[page][index]
  isLoading: boolean;
  isValidating: boolean;
  error: any;
  type: 'following' | 'followedBy';
  addPage: () => void;
  endOfFollowers: boolean;
};

const useFollowers = (
  type: 'following' | 'followedBy',
  uid: string,
): UseFollowers => {
  const [lastItem, setLastItem] = useState<string | null>(null);

  const fetcher = useCallback(
    async (key: string) => {
      const isFirstPage = key.substring(key.length - 1) === '0';

      const ref = collection(
        db,
        `following/${uid}/${
          type === 'following' ? `userFollowing` : `userFollowers`
        }`,
      );
      const constraints = [
        orderBy('created', 'desc'),
        limit(ITEMS_PER_PAGE),
      ] as any[];

      if (lastItem && !isFirstPage) {
        constraints.push(startAfter(lastItem));
      }

      return getDocs(query(ref, ...constraints)).then(querySnapshot => {
        const profiles = [] as string[];
        let lastDoc: QueryDocumentSnapshot | null = null;
        querySnapshot.forEach(doc => {
          profiles.push(doc.id);
          lastDoc = doc;
        });
        return { profiles, lastDoc };
      });
    },
    [type, uid, lastItem],
  );

  const swrKey = (pageIndex: number) => {
    return `/followers/${type}/${uid}/?page=${pageIndex}`;
  };

  const { data, error, isLoading, isValidating, setSize } = useSWRInfinite(
    swrKey,
    fetcher,
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateFirstPage: false,
      refreshInterval: 0,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    },
  );

  useEffect(() => {
    if (data && data[0]) {
      setLastItem(data[data.length - 1].lastDoc);
    }
  }, [data]);

  const addPage = () => {
    setSize(size => size + 1);
  };

  return {
    followers: data,
    type,
    isLoading,
    isValidating,
    error,
    addPage,
    endOfFollowers:
      data && data[data.length - 1].profiles.length < ITEMS_PER_PAGE
        ? true
        : false,
  };
};

export default useFollowers;
