import { mdiAccountCancel } from '@mdi/js';
import Icon from '@mdi/react';
import useBlock from '@/hooks/useBlock';
import ErrorMessage from '@/components/General/ErrorMessage';
import { IComment } from '@/types/Collections/comments';

const PostCommentCheck = ({
  children,
  comment,
}: {
  children: React.ReactNode;
  comment: IComment;
}): JSX.Element | null => {
  const { status, error } = useBlock(comment.author.uid);

  if (status === 'ok' || status === 'not-logged-in') {
    return <>{children}</>;
  }

  if (status === 'loading') {
    return null;
  }

  if (status === 'error') {
    return <ErrorMessage error={error} />;
  }

  if (status === 'blocking' || status === 'blocked') {
    return (
      <p className="comment-content content comment-author-blocked">
        <span className="icon">
          <Icon path={mdiAccountCancel} size={1} />
        </span>{' '}
        User blocked
      </p>
    );
  }

  return null;
};

export default PostCommentCheck;
