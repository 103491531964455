import { useState } from 'react';
import PostCommentOptionsReport from './PostCommentOptionsReport';
import { IComment } from '@/types/Collections/comments';

const PostCommentReportConfirm = ({
  comment,
  onClose,
  setModelType,
}: {
  comment: IComment;
  onClose: () => void;
  setModelType: (type: string) => void;
}): JSX.Element => {
  const [dialogType, setDialogType] = useState('default');

  switch (dialogType) {
    case 'report':
      return (
        <PostCommentOptionsReport
          comment={comment}
          setDialogType={setDialogType}
          onClose={() => onClose()}
        />
      );

    default:
      return (
        <div className="card">
          <div className="card-content">
            <h4 className="title is-4">
              Is this comment in breach of Shift.ms house rules?
            </h4>
            <div>
              <div className="field">
                <p className="help">
                  <a
                    href="https://shift.ms/support/house-rules"
                    target="_blank"
                    rel="noreferrer">
                    Read the house rules
                  </a>
                </p>
              </div>
              <div className="buttons modal-footer-buttons">
                <button
                  type="button"
                  aria-label="Close"
                  onClick={() => setModelType('default')}
                  className="button is-light is-medium">
                  Back
                </button>
                <button
                  type="button"
                  onClick={() => setDialogType('report')}
                  aria-label="OK"
                  className="button is-primary is-medium">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      );
  }
};

export default PostCommentReportConfirm;
