import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import AuthErrorMessage from '@/components/Auth/AuthErrorMessage';
import { ShiftmsError } from '@/providers/AuthProvider/types';

const ErrorPage = ({ error }: { error: ShiftmsError }): JSX.Element => (
  <div className="hero is-fullheight">
    <div className="hero-body is-justify-content-center">
      <div className="container is-narrow">
        <div className="has-text-danger has-text-centered mb-4">
          <Icon path={mdiAlertCircle} size={3} />
        </div>
        <AuthErrorMessage error={error} />
      </div>
    </div>
  </div>
);

export default ErrorPage;
