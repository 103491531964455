import Link from 'next/link';
import useUser from '@/hooks/useUser';
import Avatar from '@/components/Profile/Avatar';

const ProfileListItem = ({
  profileUid,
}: {
  profileUid: string;
}): JSX.Element | null => {
  const { user, error } = useUser(profileUid);

  if (!user || error) {
    return null;
  }

  return (
    <li>
      <Link as={`/@${user.username}`} href="/[...slug]" className="media">
        <span className="media-left">
          <span className="profile-follow-list-profile-image image">
            <Avatar
              username={user.username}
              imageUrl={user.profileImgUrlThumb || user.profileImgUrl}
              size={64}
            />
          </span>
        </span>
        <span className="media-content">
          <span className="profile-follow-list-profile-name">
            @{user.username}
          </span>
          <span className="profile-follow-list-profile-bio">{user.bio}</span>
        </span>
      </Link>
    </li>
  );
};

export default ProfileListItem;
