import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import PostCommentOptionsDelete from './PostCommentOptionsDelete';
import PostCommentReportConfirm from './PostCommentReportConfirm';
import eventAdd from '@/lib/eventAdd';
import { useAuth } from '@/providers/AuthProvider';
import { IComment } from '@/types/Collections/comments';

const CommentOptionsMenu = ({
  comment,
  onClose,
  open,
  setMentionUser,
}: {
  comment: IComment;
  onClose: () => void;
  open: boolean;
  setMentionUser: (username: string) => void;
}): JSX.Element => {
  const { uid, auth } = useAuth();
  const [modalType, setModalType] = useState('default');

  const handleClose = () => {
    onClose();
  };

  const handleExited = () => {
    setModalType('default');
  };

  const content = () => {
    switch (modalType) {
      case 'report':
        return (
          <PostCommentReportConfirm
            comment={comment}
            setModelType={setModalType}
            onClose={() => onClose()}
          />
        );

      case 'delete':
        return (
          <PostCommentOptionsDelete
            comment={comment}
            onClose={() => onClose()}
          />
        );

      case 'replyComment':
        setMentionUser(`@${comment.author.username}`);
        if (uid && auth?.email) {
          eventAdd({
            uid,
            eventType: 'COMMENT:REPLY_BTN_MODAL_CLICK',
            eventSource: 'COMMENT',
            eventSourceId: comment.id,
            meta: {},
            email: auth.email,
            platform: 'web',
          });
        }
        onClose();
        break;

      default:
        return (
          <div className="card small-dialog chatroom-modal">
            <div className="card-content">
              <div className="modal-header">
                <h2 id="post-options-title" className="is-size-4">
                  Comment options
                </h2>
                <div className="buttons modal-close-button">
                  <button
                    type="button"
                    aria-label="Close"
                    onClick={() => handleClose()}
                    className="button is-light">
                    <span className="icon">
                      <Icon path={mdiClose} size={1.25} />
                    </span>
                  </button>
                </div>
              </div>
              <div className="buttons">
                <button
                  type="button"
                  onClick={() => setModalType('replyComment')}
                  className="button reply-comment-button is-fullwidth is-outlined is-medium">
                  Reply to this comment
                </button>

                {uid === comment.author.uid ? (
                  <button
                    type="button"
                    onClick={() => setModalType('delete')}
                    className="button is-danger is-fullwidth is-outlined is-medium">
                    Delete comment
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('report')}
                    className="button is-danger is-fullwidth is-outlined is-medium">
                    Report this comment
                  </button>
                )}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      aria-labelledby="post-options-title">
      {content()}
    </Dialog>
  );
};

export default CommentOptionsMenu;
