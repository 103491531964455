import { useState, useCallback } from 'react';
import useSWR from 'swr';
import useSWRSubscription from 'swr/subscription';
import type { SWRSubscriptionOptions } from 'swr/subscription';
import {
  query,
  collection,
  where,
  limit,
  onSnapshot,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { useAuth } from '@/providers/AuthProvider';
import ErrorMessage from '@/components/General/ErrorMessage';
import PostComment from './PostComment';
import PostCommentForm from './PostCommentForm';
import { IPostWithComments } from '@/types/Collections/posts';
import { IComment } from '@/types/Collections/comments';

const PostComments = ({
  post,
}: {
  post: IPostWithComments;
}): JSX.Element | null => {
  const { isLoggedIn, isAdmin } = useAuth();
  const [mentionUser, setMentionUser] = useState<string>('');

  const commentsSubscriptionfetcher = useCallback(
    (
      key: string,
      { next }: SWRSubscriptionOptions<IComment[] | null, Error>,
    ) => {
      const constraints = [
        where('postId', '==', post.id),
        orderBy('created', 'asc'),
      ] as any[];

      if (!isAdmin) {
        constraints.push(where('isPublished', '==', true));
      }

      const q = query(collection(db, 'comments'), ...constraints);

      const unsubscribe = onSnapshot(
        q,
        querySnapshot => {
          const comments = [] as IComment[];
          querySnapshot.forEach(doc => {
            comments.push({ id: doc.id, ...doc.data() } as IComment);
          });
          next(null, comments);
        },
        err => {
          next(err, null);
        },
      );

      return () => unsubscribe();
    },
    [isAdmin, post.id],
  );

  const { data: comments, error } = useSWRSubscription(
    post.id && isLoggedIn ? `/posts/${post.id}/comments` : null,
    commentsSubscriptionfetcher,
  );

  const commentsFetcher = useCallback(async () => {
    return getDocs(
      query(
        collection(db, 'comments'),
        where('postId', '==', post.id),
        where('isPublished', '==', true),
        orderBy('created', 'asc'),
        limit(2),
      ),
    ).then(querySnapshot => {
      const comments = [] as IComment[];
      querySnapshot.forEach(doc => {
        comments.push({ id: doc.id, ...doc.data() } as IComment);
      });
      return comments;
    });
  }, [post.id]);

  const { data: loggedOutComments } = useSWR(
    post.id && !isLoggedIn ? `/posts/${post.id}/comments` : null,
    commentsFetcher,
    {
      fallbackData: post.comments ? post.comments : undefined,
    },
  );

  if (post.isDeleted || !post.isPublished) {
    return null;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const renderComments = () => {
    let commentsList = [] as IComment[];
    if (comments) {
      commentsList = [...comments];
    } else if (loggedOutComments) {
      commentsList = [...loggedOutComments];
    }

    return commentsList.map(comment => (
      <PostComment
        setMentionUser={setMentionUser}
        key={comment.id}
        comment={comment}
      />
    ));
  };

  return (
    <>
      <div
        className={`post-comments is-clearfix${
          isLoggedIn ? '' : ' not-authenticated'
        }`}>
        {renderComments()}
      </div>
      {!post.isLocked && (
        <PostCommentForm
          setMentionUser={setMentionUser}
          mentionUser={mentionUser}
          post={post}
        />
      )}
    </>
  );
};

export default PostComments;
