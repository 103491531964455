import { FirebaseError } from '@/providers/AuthProvider/types';
import { AuthError } from '@/providers/AuthProvider/lib/authResponse';

const ErrorMessage = ({
  error,
}: {
  error: Error | FirebaseError | AuthError | undefined;
}): JSX.Element | null => {
  if (error) {
    return (
      <div className="message is-danger mb-4">
        <div className="message-header">Error</div>
        <div className="message-body">{error.message}</div>
      </div>
    );
  }
  return null;
};

export default ErrorMessage;
