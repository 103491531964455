import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import autosize from 'autosize';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where,
  addDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import search from '@/algolia/search';
import useInput from '@/hooks/inputHook';
import * as gtag from '@/lib/gtag';
import { useAuth } from '@/providers/AuthProvider';
import debouncePromise from '../../../lib/debounce';
import mentionStyles from './styles';
import { db, functions } from '@/firebase/firebase';

const PostCommentForm = ({ post, mentionUser, setMentionUser }) => {
  const { uid, user, isAdmin, isVerified, settings, showVerifyEmailDialog } =
    useAuth();
  const router = useRouter();
  const ref = useRef(null);
  const { postReply } = router.query;

  useEffect(() => {
    autosize(document.querySelector('textarea.resizeabletextarea'));
  }, []);

  const {
    value: content,
    setValue: setContent,
    bind: bindContent,
    reset: resetContent,
  } = useInput('');

  const handleClickInput = () => {
    if (ref.current) {
      ref.current.focus();
      ref.current.click();
    }
  };

  useEffect(() => {
    if (postReply === 'true' || mentionUser) {
      setContent(`${mentionUser}  `);
      handleClickInput();
    }
  }, [mentionUser, setContent, postReply]);

  useEffect(() => {
    if (!content) {
      setMentionUser('');
    }
  }, [content, setMentionUser]);

  const trimContent = str => str.toString().trim();

  const handleSave = async () => {
    const trimmedContent = trimContent(content);

    if (trimmedContent.length === 0) {
      return false;
    }

    const createdAt = serverTimestamp();
    const commentData = {
      content: trimmedContent,
      postId: post.id,
      postStreamActivityId: post.streamActivityId,
      postAuthor: post.author,
      author: {
        username: user.username,
        uid,
        profileImgUrl: user.profileImgUrlThumb || user.profileImgUrl || null,
        genderOption: user.genderOption ? user.genderOption : 'Other',
      },
      created: createdAt,
      isPublished: true,
      isDeleted: false,
      isReported: false,
      lastActive: createdAt,
      modified: createdAt,
      platform: 'web',
    };

    addDoc(collection(db, 'comments'), commentData);
    if (!settings?.isNpsSeen) {
      const comments = await getDocs(
        query(
          collection(db, 'comments'),
          where('author.username', '==', user.username),
          where('isDeleted', '==', false),
          where('isPublished', '==', true),
          limit(2),
        ),
      );
      if (comments.docs.length === 1) {
        updateDoc(doc(db, 'settings', uid), {
          firstCommentTime: createdAt,
        });
      }
    }

    gtag.event({
      action: 'post.comment.add',
      params: {
        postId: post.id,
        postTitle: post.title,
        bodyLength: trimmedContent.length,
        uid,
        // postCommentsCount: commentsLength + 1 || 0,
        userCommentsCount: user.commentsCount + 1 || 0,
      },
    });
    resetContent();
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.target.blur();
    if (isVerified) {
      handleSave();
    } else {
      showVerifyEmailDialog();
    }
  };

  const handleOnBlur = () => {
    if (postReply === 'true') {
      const [route] = router.asPath.split('?');
      const slug = route.split('/');
      router.push(`/${slug[1]}/${slug[2]}?postReply=${false}`, undefined, {
        scroll: false,
      });
    }
  };

  const handleStreamActivityIdRefresh = async () => {
    const streamFunction = httpsCallable(
      functions,
      'streamCallable-refreshPostStreamActivityId',
    );
    await streamFunction({
      id: post.id,
    });
    router.reload();
  };

  const getUsers = useCallback(
    debouncePromise(async (searchQuery, callback) => {
      if (query !== '') {
        const collectionRef =
          process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_USERS_INDEX;
        const page = 1;
        const res = await search(searchQuery, collectionRef, 6, page);
        const data = res.hits.map(u => ({ display: u.username, id: u.uid }));
        callback(data);
      }
    }, 500),
    [],
  );

  /* const onEnterPress = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      event.target.blur();
      handleSave();
    }
  }; */

  if (!uid) {
    return null;
  }

  if (!post.streamActivityId) {
    const now = new Date();
    const fiveMinsAgo = new Date(now.getTime() - 5 * 60000);
    let postDate = post.created;
    if (!(postDate instanceof Date) && typeof postDate.toDate === 'function') {
      postDate = postDate.toDate();
    }
    if (!post.streamActivityId && postDate > fiveMinsAgo) {
      return (
        <div className="comment-create-form">
          <div>Still saving data, try again...</div>
        </div>
      );
    }

    return (
      <div className="comment-create-form">
        <p className="is-font-brand-bold mb-4">
          Sorry, comments are unavailable at this time.
        </p>
        {uid === post.author.uid || isAdmin ? (
          <div className="buttons">
            <button
              onClick={handleStreamActivityIdRefresh}
              type="button"
              className="button">
              Refresh
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <div className="comment-create-form">
      <form onSubmit={handleSubmit}>
        <div className="media mention-media">
          <figure className="media-left">
            <button
              type="submit"
              aria-label="Send"
              className="button is-rounded is-warning">
              <span className="icon">
                <Icon path={mdiSend} size={1} title="Send" />
              </span>
            </button>
          </figure>
          <div className="media-content">
            <div className="field is-grouped">
              <div className="mention-control control is-expanded">
                <MentionsInput
                  inputRef={ref}
                  style={mentionStyles}
                  onBlur={handleOnBlur}
                  classNames={{
                    textarea__input: 'textarea resizeabletextarea',
                  }}
                  placeholder=" Write your comment..."
                  {...bindContent}>
                  <Mention
                    appendSpaceOnAdd
                    displayTransform={(id, display) => `@${display}`}
                    trigger="@"
                    data={getUsers}
                    markup="@__display__ "
                    className="is-hidden"
                  />
                </MentionsInput>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

PostCommentForm.propTypes = {
  post: PropTypes.object.isRequired,
  mentionUser: PropTypes.string.isRequired,
  setMentionUser: PropTypes.func.isRequired,
};

export default PostCommentForm;
