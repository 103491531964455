import React from 'react';
import useSWR from 'swr';
import { getDocs, query, where, collection } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import PostCard from '@/components/Stream/PostCard';
import { IProfile } from '@/types/Collections/users';
import { IPost } from '@/types/Collections/posts';

const ProfileScheduled = ({ profile }: { profile: IProfile }): JSX.Element => {
  const fetcher = async (key: string): Promise<IPost[]> => {
    const [, , uid] = key.split('/');

    const q = query(
      collection(db, 'posts'),
      where('author.uid', '==', uid),
      where('isScheduled', '==', true),
    );
    return getDocs(q).then(querySnapshot => {
      const posts = [] as IPost[];
      querySnapshot.forEach(doc => {
        posts.push({ id: doc.id, ...doc.data() } as IPost);
      });
      return posts;
    });
  };

  const { data: posts, isLoading } = useSWR(
    profile.uid ? `/posts/${profile.uid}/scheduled` : null,
    fetcher,
  );

  if (isLoading) {
    return <div className="loading-block" />;
  }

  if (!posts || !posts.length) {
    return (
      <div className="container is-narrow">
        <div className="card has-background-dark mt-4">
          <div className="card-content has-text-white">
            <h2 className="title is-size-2 has-text-white has-text-centered">
              When you schedule a post it will appear here.
            </h2>
          </div>
        </div>
      </div>
    );
  }

  const postsList = () => {
    return posts.map(post => <PostCard key={post.id} post={post} />);
  };

  return (
    <div className="container is-narrow">
      <div className="posts">{postsList()}</div>
    </div>
  );
};

export default ProfileScheduled;
