import 'linkifyjs';
import Linkify from 'linkify-react';
import 'linkify-plugin-hashtag';
import 'linkify-plugin-mention';
import Link from 'next/link';
import { useAuth } from '@/providers/AuthProvider';
import DateTimeFormatted from '@/components/General/DateTimeFormatted';
import Avatar from '@/components/Profile/Avatar';
import PostCommentCheck from './PostCommentCheck';
import PostCommentModerate from './PostCommentModerate';
import PostCommentOptions from './PostCommentOptions';
import PostCommentReaction from './PostCommentReaction';
import PostCommentAuthor from './PostCommentAuthor';
import { IComment } from '@/types/Collections/comments';

const PostComment = ({
  comment,
  setMentionUser,
}: {
  comment: IComment;
  setMentionUser: (username: string) => void;
}): JSX.Element | null => {
  const { isAdmin, uid } = useAuth();

  if (!isAdmin && !comment.isPublished) {
    return null;
  }

  return (
    <PostCommentCheck comment={comment}>
      <div
        id={`post-comment-${comment.id}`}
        className={`comment comment-${
          uid === comment.author.uid ? 'sent' : 'received'
        }${comment.isDeleted ? ' comment-deleted' : ''}${
          !comment.isPublished ? ' comment-unpublished' : ''
        }`}>
        <div className="media">
          <figure className="media-left">
            <div className="image is-32x32 comment-avatar">
              <Link
                legacyBehavior
                as={`/@${comment.author.username}`}
                href="/[...slug]">
                <a>
                  <Avatar
                    username={comment.author.username}
                    imageUrl={comment.author.profileImgUrl}
                  />
                </a>
              </Link>
            </div>
          </figure>
          <div className="media-content">
            <PostCommentAuthor author={comment.author} uid={uid} />
            <div>
              <p className="comment-content content">
                <Linkify
                  options={{
                    formatHref: {
                      hashtag: val => `/hashtag/${val.substr(1)}`,
                      mention: val => `/@${val.substr(1)}`,
                    },
                  }}>
                  {comment.content}
                </Linkify>
              </p>
              <div className="comment-meta">
                <div className="comment-date">
                  {comment.created && (
                    <DateTimeFormatted timestamp={comment.created} />
                  )}
                </div>
                {comment.isDeleted || !comment.isPublished || !uid ? (
                  ''
                ) : (
                  <PostCommentOptions
                    setMentionUser={setMentionUser}
                    comment={comment}
                  />
                )}
              </div>
              {isAdmin ? <PostCommentModerate comment={comment} /> : ''}
            </div>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>
              {`
                .comment-content {
                  white-space: pre-line;
                }
              `}
            </style>
          </div>
        </div>
        <div className="comment-reactions">
          {comment && !comment.isDeleted && comment.isPublished ? (
            <PostCommentReaction comment={comment} />
          ) : (
            ''
          )}
        </div>
      </div>
    </PostCommentCheck>
  );
};

export default PostComment;
