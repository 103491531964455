import useBlock from '@/hooks/useBlock';
import { IProfile } from '@/types/Collections/users';

const ProfileBlock = ({
  profile,
  status,
}: {
  profile: IProfile;
  status: string;
}): JSX.Element | null => {
  const { unblock } = useBlock(profile.uid);

  const handleUnblock = async () => {
    if (typeof unblock === 'function') {
      unblock();
    }
  };

  if (status === 'locked') {
    return (
      <div className="container is-narrow">
        <div className="box content">
          <h1 className="title">This account has been locked</h1>
          <p>
            A moderator has locked this profile as it did not comply with our
            house rules.
          </p>
        </div>
      </div>
    );
  }

  if (status === 'blocking') {
    return (
      <div className="container is-narrow">
        <div className="box content">
          <h1 className="title">@{profile.username} is blocked</h1>
          <p>
            You can&apos;t view this profile as you have previously blocked it.
          </p>
          <div className="buttons">
            <button
              type="button"
              onClick={handleUnblock}
              className="button is-danger">
              Unblock
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (status === 'blocked') {
    return (
      <div className="container is-narrow">
        <div className="box content">
          <h1 className="title">
            @{profile.username} has blocked you from viewing their profile
          </h1>
          <p>
            You won&apos;t be able to view their personal information or comment
            on their posts.
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default ProfileBlock;
