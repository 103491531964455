import useSWR from 'swr';
import { useCallback } from 'react';
import { getDocs, query, collection, where, orderBy } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { useAuth } from '@/providers/AuthProvider';
import { ICollection } from '@/types/Collections/collections';

type UseUserCollections = {
  collections: ICollection[] | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
};

// TODO: Ensure all collections have an isDeleted field and then query here

const useUserCollections = (uid: string): UseUserCollections => {
  const { authLoaded, isAdmin, uid: viewerUid } = useAuth();

  const fetcher = useCallback(async () => {
    const ref = collection(db, `userCollections/${uid}/collections`);
    const constraints = [orderBy('lastActive', 'desc')] as any[];
    if (!isAdmin && viewerUid !== uid) {
      constraints.push(where('isPrivate', '==', false));
    }
    return getDocs(query(ref, ...constraints)).then(querySnapshot => {
      const collections = [] as ICollection[];
      querySnapshot.forEach(doc => {
        collections.push({ ...doc.data(), id: doc.id } as ICollection);
      });
      return collections;
    });
  }, [isAdmin, uid, viewerUid]);

  const { data, error, isLoading, isValidating } = useSWR(
    authLoaded && uid ? `/userCollections/${uid}` : null,
    fetcher,
  );

  return {
    collections: data,
    isLoading,
    isValidating,
    error,
  };
};

export default useUserCollections;
