import useSWR from 'swr';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { IPost } from '@/types/Collections/posts';

type UsePost = {
  post: IPost | null;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
};

const usePost = (postId: string, initialData: IPost | null = null): UsePost => {
  const fetcher = async () => {
    return getDoc(doc(db, 'posts', postId)).then(doc => {
      if (doc.exists()) {
        return { ...doc.data(), id: doc.id } as IPost;
      } else {
        return null;
      }
    });
  };

  const { data, error, isLoading, isValidating } = useSWR(
    `/posts/${postId}`,
    fetcher,
    {
      fallbackData: initialData,
    },
  );

  return {
    post: data,
    isLoading,
    isValidating,
    error,
  };
};

export default usePost;
