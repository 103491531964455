import { mdiCog, mdiClock } from '@mdi/js';
import Icon from '@mdi/react';
import Link from 'next/link';
import { useAuth } from '@/providers/AuthProvider';
import RoundedNumber from '@/components/General/RoundedNumber';
import Avatar from './Avatar';
import ProfileFollowButton from './ProfileFollowButton';
import ProfileProfileImgUpload from './ProfileProfileImgUpload';
import { IProfile } from '@/types/Collections/users';
import Image from 'next/image';

const ProfileHeader = (props: { profile: IProfile }): JSX.Element => {
  const { profile } = props;
  const { uid, isAdmin } = useAuth();

  return (
    <div className="container is-narrow">
      <header className="profile-header">
        <figure className="profile-image">
          {profile && uid === profile.uid ? (
            <div className="image is-128x128">
              <ProfileProfileImgUpload
                imageName={profile.profileImgName ? profile.profileImgName : ''}
              />
              {!profile.profileImgUrl || profile.profileImgUrl === '' ? (
                <span className="profile-avatar-prompt tag is-tooltip is-primary">
                  Click here to upload a profile picture
                </span>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="image is-128x128">
              <Avatar
                imageUrl={profile.profileImgUrlThumb || profile.profileImgUrl}
                username={profile.username}
                size={128}
              />
            </div>
          )}
        </figure>
        {profile.isDonor && (
          <div className="profile-header-badge">
            <a
              className="is-block"
              href="https://shift.ms/get-involved/donate"
              target="_blank"
              title="Link to One Tap Connection campaign">
              <Image
                width={256}
                height={256}
                src="/assets/images/otc_supporter_badge.png"
                alt="One Tap Connection Supporter"
              />
            </a>
          </div>
        )}
        <div className="profile-name">
          <h1 className="profile-display-name title is-3">{`@${profile.username}`}</h1>
        </div>
        <div className="profile-stats">
          <p>
            <Link
              legacyBehavior
              as={`/@${profile.username}/followers`}
              href="/[...slug]">
              <a>
                <span className="has-text-weight-bold">
                  <RoundedNumber
                    number={profile.followerCount ? profile.followerCount : 0}
                  />
                </span>{' '}
                <span className="">Followers</span>
              </a>
            </Link>
            {', '}
            <Link
              legacyBehavior
              as={`/@${profile.username}/following`}
              href="/[...slug]">
              <a>
                <span className="has-text-weight-bold">
                  <RoundedNumber
                    number={profile.followingCount ? profile.followingCount : 0}
                  />
                </span>{' '}
                <span className="">Following</span>
              </a>
            </Link>
          </p>
        </div>
        {profile.isDeleted ? (
          <div className="notification is-danger my-4">
            <p>Profile deleted</p>{' '}
            <p>
              {profile.deletedReason ? profile.deletedReason : 'Unknown'}:{' '}
              {profile.deletedBy ? profile.deletedBy : 'Unknown'}
            </p>
          </div>
        ) : (
          <div className="profile-header-buttons">
            <ProfileFollowButton
              profile={profile}
              showUnfollow
              showMenu
              fullWidth="is-fullwidth"
            />
          </div>
        )}
        {profile && uid === profile.uid ? (
          <div className="buttons is-centered">
            <Link legacyBehavior href="/settings">
              <a className="button">
                <span className="icon">
                  <Icon path={mdiCog} size={1} />
                </span>
                <span>Settings</span>
              </a>
            </Link>
            {isAdmin && (
              <Link href={`/@${profile.username}/scheduled`} className="button">
                <span className="icon">
                  <Icon path={mdiClock} size={1} />
                </span>
                <span>Scheduled</span>
              </Link>
            )}
          </div>
        ) : (
          ''
        )}
      </header>
    </div>
  );
};

export default ProfileHeader;
