import Link from 'next/link';
import AuthorMiniBio from './AuthorMiniBio';
import PostCardAuthorBadge from './PostCardAuthorBadge';
import { IAuthor } from '@/types/Collections/users';
import useUser from '@/hooks/useUser';

const PostCommentAuthor = ({
  author,
  uid,
}: {
  author: IAuthor;
  uid: string | null;
}): JSX.Element => {
  const { user, error } = useUser(author.uid ? author.uid : null);

  return (
    <div className="comment-author">
      <div className="comment-author-user">
        <Link legacyBehavior as={`/@${author.username}`} href="/[...slug]">
          <a className="comment-author-username is-font-brand-bold mr-1">
            @{author.username}
          </a>
        </Link>
        {user && user.isDonor && <PostCardAuthorBadge />}
      </div>
      {uid !== author.uid && <AuthorMiniBio user={user} error={error} />}
    </div>
  );
};

export default PostCommentAuthor;
