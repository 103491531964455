import { mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { flag, name } from 'country-emoji';
import Image from 'next/image';
import Link from 'next/link';
import dayjs from 'dayjs';
import { useAuth } from '@/providers/AuthProvider';
import mser from '@/public/assets/images/mser.png';
import DateTimeFormatted from '@/components/General/DateTimeFormatted';
import Age from './Age';
import { IProfile } from '@/types/Collections/users';

const ProfileAboutBio = ({
  profile,
  viewerUid,
}: {
  profile: IProfile;
  viewerUid: string | null;
}): JSX.Element | null => {
  if (profile.bio && profile.bio !== '') {
    return (
      <dl>
        <dt>Bio</dt>
        <dd className="profile-bio">{profile.bio}</dd>
      </dl>
    );
  }
  if (viewerUid === profile.uid) {
    <dl>
      <dt>Bio</dt>
      <dd>
        <div className="content">
          <div className="media">
            <div className="media-left">
              <Image
                src={mser}
                alt="Shift.ms person"
                style={{ maxHeight: '100px' }}
              />
            </div>
            <div className="media-content">
              <p>Add a short bio to tell everyone a bit about you.</p>
              <div className="buttons">
                <Link
                  legacyBehavior
                  href="/settings/profile"
                  as="/settings/profile">
                  <a className="button is-info is-rounded">
                    <span className="icon">
                      <Icon path={mdiOpenInNew} size={1} />
                    </span>
                    <span>Edit profile</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </dd>
    </dl>;
  }
  return null;
};

const ProfileAbout = ({ profile }: { profile: IProfile }): JSX.Element => {
  const { uid } = useAuth();

  return (
    <div className="container is-narrow">
      <div className="profile-about-section">
        <div className="profile-details">
          <ProfileAboutBio profile={profile} viewerUid={uid} />
          {profile.dob ? (
            <dl className="profile-age">
              <dt>Age</dt>
              <dd>
                <Age timestamp={profile.dob} />
              </dd>
            </dl>
          ) : (
            ''
          )}
          {profile.country ? (
            <dl>
              <dt>Location</dt>
              <dd>
                {flag(profile.country)} {name(profile.country)}
              </dd>
            </dl>
          ) : (
            ''
          )}
          {profile.msConnection ? (
            <dl>
              <dt>Connection to MS</dt>
              <dd>{profile.msConnection}</dd>
            </dl>
          ) : (
            ''
          )}
          {profile.msConnection === 'I have MS' && profile?.msType ? (
            <dl>
              <dt>MS type</dt>
              <dd>{profile.msType}</dd>
            </dl>
          ) : (
            ''
          )}
          {profile.msConnection === 'I have MS' && profile.yearDiagnosed ? (
            <dl>
              <dt>Diagnosis date</dt>
              <dd>
                {profile?.monthDiagnosed && (
                  <span className="mr-1">
                    {dayjs()
                      .month(profile.monthDiagnosed - 1)
                      .format('MMMM')}
                  </span>
                )}
                <span>{profile.yearDiagnosed}</span>
              </dd>
            </dl>
          ) : (
            ''
          )}
          {profile.msConnection === 'I have MS' && profile.treatment ? (
            <dl>
              <dt>Treatment choice</dt>
              <dd>{profile.treatment}</dd>
            </dl>
          ) : (
            ''
          )}
          <dl>
            <dt>Member since</dt>
            <dd>
              <DateTimeFormatted
                timestamp={profile.created}
                format="D MMM YYYY"
              />
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ProfileAbout;
