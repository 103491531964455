import useSWR from 'swr';
import { getDocs, query, collection, where, limit } from 'firebase/firestore';
import { db } from '@/firebase/firebase';
import { IProfile } from '@/types/Collections/users';
import { useAuth } from '@/providers/AuthProvider';

type UseProfile = {
  profile: IProfile | null | undefined;
  isLoading: boolean;
  isValidating: boolean;
  error: any;
};

const useProfile = (username: string | null): UseProfile => {
  const { isLoggedIn, isAdmin } = useAuth();

  const fetcher = async (key: string) => {
    const [, , username] = key.split('/');

    const queryConstraints = [
      where('usernameLowercase', '==', username.toLowerCase()),
    ];

    const q = query(collection(db, 'users'), ...queryConstraints, limit(1));

    const querySnapshot = await getDocs(q);
    let profile = null;
    querySnapshot.forEach(doc => {
      if (!doc.data().isDeleted || isAdmin) {
        profile = { ...doc.data(), uid: doc.id } as IProfile;
      }
    });
    if (profile) {
      return profile;
    }
    throw new Error('Profile not found.');
  };

  const { data, error, isLoading, isValidating } = useSWR(
    isLoggedIn && username ? `/profiles/${username}` : null,
    fetcher,
  );

  return {
    profile: data,
    isLoading,
    isValidating,
    error,
  };
};

export default useProfile;
